//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ElSlider from '@/components/common/Slider'
export default {
  name: 'NewsSection',
  components: {
    ElSlider,
  },
  data() {
    return {
      options: {
        slidesPerView: 3,
        spacing: 40,
        loop: false,
        duration: 1000,
        breakpoints: {
          '(max-width: 1280px)': {
            slidesPerView: 2,
            spacing: 10,
            loop: true,
          },
          '(max-width: 640px)': {
            slidesPerView: 1,
            loop: true,
            dragStart: () => {
              this.setPause(true)
            },
            dragEnd: () => {
              this.setPause(false)
            },
          },
        },
      },
      showSlider: false,
      current: 1,
      sliderSize: 0,
      pause: false,
      interval: null,
      hideArrow: false,
    }
  },
  computed: {
    news() {
      return this.$getBullets('press') || []
    },
  },
  mounted() {
    if (this.$getBullets('press').length) {
      window.addEventListener('resize', this.resize, true)
      this.resize()
      this.sliderSize = this.$getBullets('press').length
    }
  },
  beforeDestroy() {
    if (this.$refs.slider) {
      this.$refs.slider.slider.destroy()
      this.resetInterval()
    }
  },
  methods: {
    resize() {
      if (
        this.$getTextSetting('showPressBlock') &&
        this.$refs.slider !== undefined
      ) {
        this.hideArrow =
          this.$refs.slider.slider.details().size >
          this.$refs.slider.slider.details().slidesPerView
        this.sliderSize = this.$refs.slider.slider.details().size
        this.options.slideChanged = (s) => {
          this.current = s.details().relativeSlide
        }
        this.$refs.slider.slider.refresh(this.options)
      }
    },
    setPause(active) {
      this.pause = active
      this.setInterval()
    },
    resetInterval() {
      clearInterval(this.interval)
    },
    setInterval() {
      if (this.$refs.slider) {
        this.resetInterval()
        this.interval = setInterval(() => {
          if (!this.pause) {
            this.$refs.slider.slider.next()
          }
        }, 3000)
      }
    },
    newsPhoto(imageFile) {
      if (imageFile) {
        return imageFile.url
      }

      return require('~/assets/img/partner/main/news-section/forbes.svg')
    },
  },
}
